import React, {useEffect, useState} from 'react';
import {
    Container,
    Typography,
    Paper,
    Grid,
    Button, TableCell, Box,
} from '@mui/material';
import {useLocation} from "react-router-dom";
import {Image} from "@mui/icons-material";
import {StyledBtn} from "../design/StandardBtn";
import {StyledImg} from "../design/StandardImg";


const ResultRecommendAiddoru = () => {
    const location = useLocation();
    const idol = location.state.recommendedIdol;

    useEffect(() => {

    })

    const buttonStyle = {
        borderRadius: 4,
        color: "black",
        backgroundColor : "white",
        marginBottom: 4,
        display: 'flex',
    };


    return (
        <Container style={{ backgroundColor: '#007BFF', borderRadius: 4 }}>
            <Typography variant="h4" style={{ color: '#FFFFFF', marginBottom: '20px', textAlign: 'center' }}>
                당신이 찾던 Aiddoru는...
            </Typography>

            <Box sx={{color:"white"}}>
                <img src={`${idol.channelThumbnailURL}`} />
                <Typography variant="h2" gutterBottom
                            onClick={() => {
                                window.open("about:blank").location.href=`${idol.channelUrl}`;
                            }}>
                    {idol.channelName}</Typography>

                {idol.idolToday.subscribeDiffPercentage && idol.idolToday.subscribeDiffPercentage != 0 &&
                    (
                        <Box>
                            최근 상승세
                            <Typography variant="h2" >{idol.idolToday.subscribeDiffPercentage} %</Typography>)
                        </Box>
                    )
                }


                <Box>
                    총 구독자수
                    <Typography variant="h2" >{idol.subscriberCount} </Typography>
                    <Button style={buttonStyle} onClick={() => {window.location.href=`${idol.channelUrl}`}}>채널로 이동</Button>
                    <Button style={buttonStyle} onClick={() => {window.location.href=`/community/${idol.channelName.split(" ")[0]}`}}>커뮤니티로 이동</Button>
                </Box>

                <Box>
                    {idol.idolVideoInfoList?.length > 0 && (
                        <div>
                            <Typography variant="h2">최근 영상</Typography>
                            {idol.idolVideoInfoList.map((idolVideoInfo, index) => (
                                <div key={index}>
                                    <StyledImg src={idolVideoInfo.videoThumbnailURL} style={{ marginLeft: "0.5rem" }} />
                                </div>
                            ))}
                        </div>
                    )}
                </Box>

                <Box sx={{height:50}}></Box>
            </Box>

        </Container>
    );
};

export default ResultRecommendAiddoru;

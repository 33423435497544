import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import 'moment/locale/ko';

moment.locale('ko');

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', borderRadius: '4px', padding: '10px' }}>
                <p className="label" style={{ fontWeight: 'bold', margin: '0' }}>{`날짜: ${moment(label).format('YYYY년 MM월 DD일')}`}</p>
                <p className="intro" style={{ margin: '5px 0' }}>{`구독자 수: ${payload[0].value}`}</p>
            </div>
        );
    }
    return null;
};

const TimeValueChart = ({ subscriberTraceList }) => {
    const [data, setData] = useState([]);
    const [minVal,setMinVal] = useState(0);

    useEffect(() => {
        if (Array.isArray(subscriberTraceList)) {
            setData(subscriberTraceList);
            subscriberTraceList.forEach((val) => setMinVal(Math.min(val,minVal)));
        } else {
            setData([]);
        }

    }, [subscriberTraceList]);

    if (!data || data.length === 0) {
        return <div>No data available</div>;
    }

    return (

        <div style={{ width: '100%', height: 200 }}>


            <ResponsiveContainer width="100%" height={200} style={{ backgroundColor: '#fff' }}>

                <LineChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" horizontal={false} vertical={false} />
                    <XAxis
                        dataKey="createdDate"
                        tickFormatter={tick => moment(tick).format('MM월 DD일')}
                        style={{ fontSize: '12px', color: '#666' }}
                    />
                    <YAxis
                        tickFormatter={tick => `${tick}`}
                        style={{ fontSize: '12px', color: '#666' }}
                        domain={[minVal - 10000, 'auto']}


                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                        formatter={(value) => <span>구독자 수</span>}
                    />
                    <Line
                        type="monotone"
                        dataKey="subscriberCount"
                        stroke="#82ca9d"
                        strokeWidth={3}
                        dot={{ r: 4, stroke: '#82ca9d', strokeWidth: 2, fill: '#fff' }}
                        activeDot={{ r: 4, stroke: '#82ca9d', strokeWidth: 2, fill: '#fff' }}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default TimeValueChart;
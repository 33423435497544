import React, { useEffect, useState } from "react";
import {Box, Button, Card, CardContent, CardMedia, Container, Grid, IconButton, Typography} from "@mui/material";
import { useAuthenticatedFetch } from "../contexts/AuthenticatedFetch";
import { styled } from '@mui/material/styles';
import GroupIcon from "@mui/icons-material/Group";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import {StyledImg} from "../design/StandardImg";
import TagButtons from "../design/TagButtons";
import ShuffleIcon from "@mui/icons-material/Shuffle";

// 스타일이 적용된 카드 컴포넌트
const StyledCard = styled(Card)(({ theme }) => ({
    borderRadius: '12px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2), 0 8px 24px rgba(0, 0, 0, 0.15)',
    },
}));

// 스타일이 적용된 버튼 컴포넌트
const StyledButton = styled(Button)(({ theme }) => ({
    width: '100%',
    border: 'none',
    borderRadius: '8px',
    background: 'linear-gradient(145deg, #00bcd4, #0097a7)',
    boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.2), -5px -5px 15px rgba(255, 255, 255, 0.9)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'none',
    '&:hover': {
        background: 'linear-gradient(145deg, #e0e0e0, #f9f9f9)',
        boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.2), -5px -5px 15px rgba(255, 255, 255, 0.9)',
    },
    color: 'white',
}));

const RecommendIdolByTag = () => {
    const [recommendIdolList, setRecommendIdolList] = useState([]);
    const authenticatedFetch = useAuthenticatedFetch();

    const getRecommendIdolList = async () => {
        const url = "/api/recommendAiddoru/";
        try {
            const response = await authenticatedFetch(url, {
                method: "GET",
            });
            const data = await response.json();

            if (response.ok) {
                setRecommendIdolList(data);
            } else {
                console.error("Failed to fetch recommend idol list:", data);
            }
        } catch (error) {
            console.error("Error fetching recommend idol list:", error);
        }
    };

    useEffect(() => {
        getRecommendIdolList();
    }, []);

    const Title = styled(Typography)(({ theme }) => ({
        fontSize: '2.5rem',
        fontWeight: 'bold',
        color: '#00bcd4',
        textAlign: 'center',
        marginBottom: theme.spacing(4),
        padding: theme.spacing(2),
        borderRadius: '8px',
        background: 'linear-gradient(135deg, #ffffff, #e0f7fa)',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        textTransform: 'uppercase',
        letterSpacing: '0.05em',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
            background: 'linear-gradient(135deg, #e0f7fa, #ffffff)',
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
        },
    }));

    const GradientButton = styled(Button)(({ theme }) => ({
        width: '100%',
        border: 'none',
        borderRadius: '8px',
        background: 'linear-gradient(145deg, #00bcd4, #0097a7)',
        boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.2), -5px -5px 15px rgba(255, 255, 255, 0.9)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        textTransform: 'none',
        position: 'relative',
        padding: '16px',
        '&:hover': {
            background: 'linear-gradient(145deg, #e0e0e0, #f9f9f9)',
            boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.2), -5px -5px 15px rgba(255, 255, 255, 0.9)',
        },
        color: 'white',
    }));

    return (
        <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Box sx={{ width: '60%',mx: 'auto', mb: 2, position: 'relative' }}> {/* Box에 relative position 추가 */}
                    <Title variant="h4" gutterBottom>
                        당신에게 추천하는 IDOL
                    </Title>
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            right: 0,
                            color: 'white',
                            background : 'linear-gradient(145deg, #00bcd4, #0097a7)',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)', // 호버 시 배경색 변경 (선택적)
                            },
                            transform: 'translateY(-50%)', // 중앙 정렬을 위한 변환
                        }}
                        onClick={(e) => {
                            getRecommendIdolList();
                            e.stopPropagation(); // 버튼 클릭 시 다른 클릭 이벤트 방지
                        }}
                    >
                        <ShuffleIcon />
                    </IconButton>
                </Box>
            </Grid>

            <Grid container spacing={2} justifyContent="center">
                {recommendIdolList && recommendIdolList.length > 0 ? (
                    recommendIdolList.map((idol) => (
                        <Grid item xs={12} key={idol.id} sx={{ width: '60%' }}>
                            <GradientButton
                                sx={{
                                    width: '60%',
                                    mx: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '16px',
                                }}
                                onClick={() => window.open(idol.channelUrl, '_blank')}
                            >
                                {/* 왼쪽: 빈 공간 */}
                                <Box sx={{ width: '25%' }} />

                                {/* 중앙: 아이돌 정보 */}
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%' }}>

                                    <img
                                        src={idol.channelThumbnailURL}
                                        alt={idol.name}
                                        style={{ width: '50px', height: '50px', borderRadius: '50%', marginBottom: '8px' }}
                                    />
                                    <Typography variant="h6" gutterBottom>
                                        {idol.channelName}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                            marginBottom: '8px'
                                        }}
                                    >
                                        <GroupIcon />
                                        {idol.subscriberCount}
                                        <TrendingUpIcon sx={{ fontSize: '20px' }} />
                                        {(idol.idolToday.subscribeDiffPercentage !== null ? idol.idolToday.subscribeDiffPercentage : 0) + "%"}
                                    </Typography>
                                    <TagButtons tagList={idol.tagList} />
                                </Box>

                                {/* 오른쪽: 최근 영상 */}
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '25%' }}>
                                    <Grid container spacing={1}>
                                        {idol.idolVideoInfoList?.length > 0 &&
                                            idol.idolVideoInfoList.slice(0,3).map((idolVideoInfo) => (
                                                <Grid item xs={4}>
                                                    <img
                                                        src={`${idolVideoInfo.videoThumbnailURL}`} // 이미지 크기 조정
                                                        alt={`최근 영상`}
                                                        style={{
                                                            width: '100%',
                                                            height: 'auto',
                                                            borderRadius: '8px'
                                                        }}
                                                    />
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Box>
                            </GradientButton>
                        </Grid>
                    ))
                ) : (
                    <Typography variant="body1" color="text.secondary">
                        추천할 아이돌이 없습니다.
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
};

export default RecommendIdolByTag;
import React, {useState} from "react";
import {
    List,
    ListItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    Box, Divider, TextField, MenuItem, Button, Modal, Collapse, IconButton
} from "@mui/material";
import authenticatedFetch from "../contexts/AuthenticatedFetch";
import RankTable from "./RankTable";
import {textColor} from "../design/StandardColor";
import {standardGrayStyle} from "../design/StandardObject";
import { styled } from '@mui/system';
import {StyledImg} from "../design/StandardImg";
import {StyledTable} from "../design/StandardObject";
import TimeValueChart from "./TimeValueChart";
import ChannelInfo from "./ChannelInfo";
import { Tooltip} from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';

const additionalInfoStyle = {
    display: "none", // 처음에 숨김
};




const getImageFileName = (id) => {
    if (id % 4 === 0) {
        return 'A.jpg';
    } else if (id % 4 === 1) {
        return 'B.jpg';
    } else if (id % 4 === 2) {
        return 'C.jpg';
    } else {
        return 'D.jpg';
    }
};


const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('ko-KR', options);
};

// 반응형 테이블 컨테이너
const ResponsiveTableContainer = styled(TableContainer)(({ theme }) => ({
    maxWidth: '100%',
    overflowX: 'auto', // 가로 스크롤 허용
    [theme.breakpoints.down('md')]: {
        padding: '8px',
    },
}));





const RankList = ({ idolList }) => {

    const [expandedRow, setExpandedRow] = useState(null);

    const clickChannel = (idolId) => {
        fetch(`/api/idol/updateClick?idolId=${idolId}`,{
            method: 'POST',
        });
    }

    const handleCellClick = (row) => {
        if (expandedRow != row) {
            setExpandedRow(row);
            clickChannel(row);
        } else {
            setExpandedRow(null);
        }
    };

    const handleClose = () => {
        setExpandedRow(null)
    };


    return (
        <>

        <TableContainer component={Paper} sx={{ overflowX: 'auto'}}>
                <Table
                    sx={{
                        borderRadius: 4,
                        minWidth: 1024,
                        '@media (max-width: 1024px)': { minWidth: '100%' }
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ padding: '8px', fontSize: '14px', width: '25%', textAlign: 'center' }}>
                                채널명
                            </TableCell>
                            <TableCell sx={{ padding: '8px', fontSize: '14px', width: '15%', textAlign: 'center' }}>
                                구독자수
                            </TableCell>
                            <TableCell sx={{ padding: '8px', fontSize: '14px', width: '20%', textAlign: 'center' }}>
                                총 조회수
                            </TableCell>
                            <TableCell sx={{ padding: '8px', fontSize: '14px', width: '15%', textAlign: 'center' }}>
                                영상수
                            </TableCell>
                            <TableCell sx={{ padding: '8px', fontSize: '14px', width: '25%', textAlign: 'center' }}>
                                최근 추세
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {idolList.length > 0 &&
                            idolList.map((idol) => (
                                <React.Fragment key={idol.id}>
                                    <TableRow
                                        onClick={() => handleCellClick(idol.id)}
                                        style={{ cursor: 'pointer' }}
                                        className="table-row"
                                        sx={{

                                        }}
                                    >
                                        <TableCell
                                            sx={{
                                                padding: '8px',
                                                fontSize: '14px',
                                                width: '25%',
                                                textAlign: 'center',

                                            }}>
                                            <Typography variant="body2" noWrap>{idol.channelName}</Typography>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                padding: '8px',
                                                fontSize: '14px',
                                                width: '15%',
                                                textAlign: 'center',

                                            }}>
                                            <Typography variant="body2" noWrap>
                                                {idol.subscriberCount.toLocaleString("en-US")}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                padding: '8px',
                                                fontSize: '14px',
                                                width: '20%',
                                                textAlign: 'center',

                                            }}>
                                            <Typography variant="body2" noWrap>
                                                {idol.viewCount.toLocaleString("en-US")}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                padding: '8px',
                                                fontSize: '14px',
                                                width: '15%',
                                                textAlign: 'center',

                                            }}>
                                            <Typography variant="body2" noWrap>
                                                {idol.videoCount}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                padding: '8px',
                                                fontSize: '14px',
                                                width: '25%',
                                                textAlign: 'center',

                                            }}>
                                            {idol.idolSubscriberTraceList?.length > 0 &&
                                                <TimeValueChart subscriberTraceList={idol.idolSubscriberTraceList} />}
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell style={{ padding: 0, border: 'none', textAlign: 'center' }} colSpan={5}>
                                            <Collapse in={expandedRow === idol.id} timeout="auto" unmountOnExit>
                                                <Box sx={{ padding: '16px' }}>
                                                    <ChannelInfo idol={idol} />
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default RankList;
